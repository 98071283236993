import React from 'react';
import PageTransition from 'gatsby-v2-plugin-page-transitions';

let PageTransitionContainer = ({ children }) => {
    return (
        <PageTransition
            defaultStyle={{
                transition: 'opacity 200ms ease-in-out',
                opacity: 0,
            }}
            transitionStyles={{
                entering: { opacity: 1 },
                entered:  { opacity: 1 },
                exiting:  { opacity: 0 },
                exited:  { opacity: 0 }
            }}
            transitionTime={200}>
            {children}
        </PageTransition>
    )
}

export default PageTransitionContainer